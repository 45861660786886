<template>
    <modal>
        <template v-slot:title>
            ChangeLog
        </template>
        <template v-slot:head-bottom>
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                @click="$emit('close')"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>
        <template v-slot:body>
            <div 
                class="container" 
                style="position: relative;"
                @click="showCoord($event)"
            >
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label 
                                for="exampleFormControlInput1" 
                                class="form-label"
                            >
                                {{ $t('project') }}
                            </label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="exampleFormControlInput1"
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label 
                                for="exampleFormControlInput1" 
                                class="form-label"
                            >
                                {{ $t('version') }}
                            </label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="exampleFormControlInput1"
                            >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label 
                                for="exampleFormControlInput1" 
                                class="form-label"
                            >
                                {{ $t('preReleaseDate') }}
                            </label>
                            <input 
                                type="date" 
                                class="form-control" 
                                id="exampleFormControlInput1"
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label 
                                for="exampleFormControlInput1" 
                                class="form-label"
                            >
                                {{ $t('dateRelease') }}
                            </label>
                            <input 
                                type="date" 
                                class="form-control" 
                                id="exampleFormControlInput1"
                            >
                        </div>
                    </div>
                </div>

                <div class="row" style="color: rgb(143, 143, 143)">
                    <div class="col-md-2">
                        <p>{{ $t('Categories') }}</p>
                    </div>
                    <div class="col-md-10">
                        <p>{{ $t('shift') }}</p>
                    </div>
                </div>

                <div
                    v-for="(row, index) in rows"
                    :key="index"
                    class="row" 
                    style="width: 100%;"
                >
                    <div class="col-md-2 " :class="this.points.find(el => el.value === row.value).class">
                        <div class="points_name" @click="showContextFunc($event)">
                            {{ this.points.find(el => el.value === row.value).name }}
                        </div>
                    </div>
                    <div class="col-md-10">
                        <input class="form-control" :placeholder="$t('shift')" />
                    </div>
                </div>

                <div 
                    v-show="this.context.showContext === true"
                    class="contextMenu" 
                    :style="`left: ${context.x}px; top: ${context.y}px`"
                >
                    
                    <div 
                        v-for="(point, index) in points"
                        :key="index"
                        :class="point.class"
                    >
                        <div class="points_name" @click="hideContextFunc(point.value)">
                            {{ point.name }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn btn-success">{{ $t("Add") }}</button>
        </template>
    </modal>
</template>

<script>
import modal from "@/components/modal-small.vue";

export default {
    data() {
        return {
            context: {
                showContext: false,
                x: 0,
                y: 0,
            },
            points: [
                { name: "✅ Додано", value: "add", class: "add_points" },
                { name: "🔨 Виправлено", value: "fix", class: "fix_points" },
                { name: "🗑️ Видалено", value: "remove", class: "remove_points" },
                { name: "✏️ Змінено", value: "edit", class: "edit_points" },
                { name: "🔄 Оновлено", value: "update", class: "update_points" },
            ],
            rows: []
        }
    },
    methods: {
        showCoord(event) {
            const modal = event.currentTarget;
            const rect = modal.getBoundingClientRect();
            this.context.x = event.clientX - rect.left;
            this.context.y = event.clientY - rect.top + 10

            console.log(this.context)
        },
        showContextFunc(event) {
            this.context.showContext = true
        },
        hideContextFunc(string) {
            console.log(string)
            this.context.showContext = false 
        }
    },
    components: { modal },
}
</script>

<style scoped>
.add_points > .points_name {
    background-color: rgba(33, 150, 83, .15);
}
.fix_points > .points_name {
    background-color: rgba(242, 153, 74, .15);
}
.remove_points > .points_name {
    background-color: rgba(235, 87, 87, .15);
}
.edit_points > .points_name {
    background-color: rgba(0, 112, 255, .15);
}
.update_points > .points_name {
    background-color: rgba(130, 128, 255, .15);
}
.points_name {
    color: black;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    display: block;
}
.contextMenu{
    position: absolute; 
    background-color: white; 
    display: flex; 
    flex-flow: wrap;
    max-width: 350px;
    gap: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 10px;
}
</style>