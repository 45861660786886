<template>
    <div class="change_container" style="position: relative;">
        <div
            v-for="(changeLog, k) in changeLogs"
            :key="k" 
            class="change_container__item"
        >
            <div class="change_container__item__title">
                <span
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        line-height: 16px;
                        color: black;
                        flex: 1
                    "   
                >
                    {{ $t('shifts') }}
                    <span
                        style="color: rgba(13, 26, 51, .6); margin-left: 5px;"
                    >
                        {{ 
                            new Date(changeLog.date).toLocaleDateString('uk-UA', 
                                { day: '2-digit', month: 'long', year: 'numeric' }
                            ) 
                        }}
                    </span>
                </span>

                <div 
                    class="author"
                    style="max-width: 150px !important"
                >
                    <img 
                        :src="changeLog.author.avatar" 
                        style="
                            border-radius: 50px;
                            width: 20px;
                            height: 20px;
                        "
                        alt="avatar"
                    />
                    {{ changeLog.author.name }}
                </div>

            </div>
                
            <div class="info_block">

                <template v-for="(items, index) in changeLog.blocks" :key="index">
                    <div
                        v-if="items.points.length > 0"
                        :class="items.class"
                        :style="index != 0 ? `border-top: 3px dotted rgba(13, 26, 51, 0.1); padding-top: 16px;` : ``"
                    >
                        <span class="points_name">
                            {{ items.name }}
                        </span>
                        <ul class="list">
                            <li class="list_point" v-for="(points, idx) in items.points" :key="idx">
                                <div class="list_point__content">
                                    {{ points.content }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            changeLogs: [
                {
                    date: '2025-02-24',
                    author: {
                        name: 'John Doe',
                        avatar: 'https://img-1.skyservice.pro/uploads/images/sky_inside/raksEn4YBDs2rZRa.png'
                    },
                    blocks: [
                        {
                            class: 'add_points',
                            name: '✅  Додано',
                            points: [
                                {
                                    content: 'fdsfsdsd',
                                },
                            ]
                        },
                        {
                            class: 'improved_points',
                            name: '🙌  Покращено',
                            points: [
                                {
                                    content: 'fdsfsdsd',
                                },
                            ]
                        },
                        {
                            class: 'fix_points',
                            name: '💪  Виправлено',
                            points: [
                                {
                                    content: 'fdsfsdsd',
                                },
                            ]
                        },
                    ]
                }

            ]
        }

    }
}
</script>

<style>
.change_container {
    width: 500px;
    padding: 20px 16px;
}
.change_container__item {
    padding: 14px 10px;
    position: absolute;
    width: 500px !important;
}
.change_container__item__title {
    display: flex;
}
.change_container__item__title > span{
    margin-bottom: 0;
}
.change_container__item__title > span::before {
    content: "•";
    font-size: 1.625rem;
    margin-right: 10px;
    color: rgba(13, 26, 51, .6)
}
.info_block {
    border-left: 1px solid #0D1A331A;
    margin-left: 5px;
    padding: 14px 30px;
}
.add_points > .points_name {
    background-color: rgba(33, 150, 83, .15);
}
.fix_points > .points_name {
    background-color: rgba(217, 234, 255, 1);
}
.improved_points > .points_name {
    background-color: rgba(225, 225, 255, 1);
}
.points_name {
    color: rgba(13, 26, 51, 1);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    display: block;
    width: fit-content;
}
.list {
    width: 100%;
    padding-left: 16px;
}
.list_point {
    display: flex;
    align-items: center;
}
.list_point::before {
    content: "•";
    color: black;
    margin-right: 8px;
    font-size: 18px;
}
.list_point__content {
    flex: 1;
    color: black
}
.author {
    padding: 5px;
    background-color: rgba(250, 250, 250); 
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    line-height: 16px;
    color: black;
}
</style>